<script>
import CompulsoryImage from '../../components/CompulsoryImage.vue';
import IconCategory from '../../components/IconCategory.vue';
//import pdf from 'vue-pdf';

export default{
    components: {CompulsoryImage, IconCategory /*pdf*/ },
    data(){
        return {
            //reglementUrl: '',
            exec: 72,
            choreo: 65,
            categories: [
                { code: 'DI', isTeam: false, compulsories: [ 'DI-03', 'DI-09', 'DI-13', 'DI-14', 'DI-17', 'DI-20']},
                { code: 'DP', isTeam: false, compulsories: [ 'DP-03', 'DP-06', 'DP-09', 'DP-12', 'DP-14', 'DP-19']},
                { code: 'DT', isTeam: true, compulsories: [ 'DT-02', 'DT-03', 'DT-04', 'DT-05', 'DT-08', 'DT-12']},
                { code: 'MI', isTeam: false, compulsories: [ 'MI-09', 'MI-17', 'MI-21', 'MI-28', 'MI-33', 'MI-34']},
                { code: 'MP', isTeam: false, compulsories: [ 'MP-03', 'MP-06', 'MP-08', 'MP-10', 'MP-12', 'MP-13']},
                { code: 'MT', isTeam: false, compulsories: [ 'MT-01', 'MT-03', 'MT-04', 'MT-06', 'MT-08', 'MT-11']},            ],
            pselCateg: 0,
            pteamOption: '3',
        }
    },
    computed:{
        reglementUrl(){ return "https://cv.ffvl.fr/sites/cv.ffvl.fr/files/Reglement-CVA-2022.pdf"; },
        seasonRulesUrl(){ return "https://cv.ffvl.fr/sites/cv.ffvl.fr/files/Regles-pour-les-competitions-CVA-Classique-2023.pdf"; },
        seasonExpertCompulsoriesUrl(){ return "https://cv.ffvl.fr/sites/cv.ffvl.fr/files/Figures-imposees-CVA-Classique-2023.pdf"; },
        seasonNoviceCompulsoriesUrl(){ return "https://cv.ffvl.fr/sites/cv.ffvl.fr/files/Figures-imposees-Novice-2023.pdf"; },
        teamOption:{
            get(){ return this.pteamOption; },
            set(newValue){ this.pteamOption = newValue; }
        }
    },
    methods:
    {
        async refresh(){
            //this.reglementUrl = (await import('@/assets/Reglement-CVA-2022.pdf')).default;
            //this.reglementUrl =  "@/assets/Reglement-CVA-2022.pdf";
        },
    },
    async mounted(){
      await this.showLoader();
      await this.refresh();
      this.$hideLoader();
    }

}
</script>

<template>
    <div>
        <h1>Championnat de France<br>de Cerf-Volant Acrobatique</h1>
        <h2>Informations générales</h2>
        <p>Le Championnat de France de Cerf-Volant Acrobatique repose sur un fonctionnement en 2 étapes sur, généralement, 2 années civiles.<br>
        Des manches qualificatives sont organisées chaque année qui donnent lieu à un classement national "provisoire" mis à jour au fil de l'eau.<br/>
        A l'issue de celles-ci, la commission compétition statut sur les compétiteurs qualifiés pour la phase finale.<br/>
        Celle-ci se déroule généralement l'année suivant en deux à trois manches réalisées sur 3 jours en un seul lieu.<br/>
        Le règlement utilisé pour cette compétition, consultable <b><a rel="prefetch" :href="reglementUrl" target="_blank">ici</a></b>, est français, bien que librement et assez largement inspiré d'un autre règlement (IRBC).
        </p>

        <h2>Infos pratiques pour la saison 2023</h2>
        <ul>
            <li><b>Règles applicables en 2023</b>
                <div>
                    Les règles applicables pour les manches sélectives se déroulant en 2023 (et comptant pour la finale du Championnat de France 2024) se trouvent ici : <a rel="prefetch" :href="seasonRulesUrl" target="_blank">{{ seasonRulesUrl }}</a> 
                </div>
            </li>
            <li><b>Figures de précision 2023</b>
                <div>
                    Le figures imposées sélectionnées pour l'ensemble des compétitions de type Championnat de France (manches sélectives pour 2024 et finale 2023) sont indiquées dans le document ci-dessus.<br/>
                    Et résumées ici : <a rel="prefetch" :href="seasonExpertCompulsoriesUrl" target="_blank">{{ seasonExpertCompulsoriesUrl }}</a>.<br/>
                    Pour les novices : <a rel="prefetch" :href="seasonNoviceCompulsoriesUrl" target="_blank">{{ seasonNoviceCompulsoriesUrl }}</a><br/>
                </div>
                <div>Pour un apperçu rapide des figures (expert) qui vous concernent :</div>
                <b-tabs lazy>
                    <b-tab v-for="category in categories" :key="category.code">
                        <template #title>
                            <icon-category variant="dark" size="xs" v-model="category.code"/>
                        </template>
                            <div v-if="category.isTeam">
                                <b-radio-group v-model="teamOption" :options="['3', '4', '5']"></b-radio-group>
                            </div>
                            <div v-for="compulsory in category.compulsories" :key="compulsory">
                                <compulsory-image includeModel includeText :value="compulsory" :teamOption="category.isTeam ? teamOption : ''"></compulsory-image>
                            </div>
                    </b-tab>
                </b-tabs>
            </li>
        </ul>
        <!--<h2>Finale du Championnat de France 2023</h2>-->


    </div>
</template>

